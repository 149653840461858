import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Menu } from '@headlessui/react';
import { FaPhoneAlt, FaPlus, FaRegComments, FaWhatsapp } from 'react-icons/fa';
import { useAccountManagerByLocale } from '@/lib/hooks/useAccountManager';
import { useWindowSize } from 'react-use';
import { getWhatsappLink } from '@/components/product/ProductAccountManager';
import classNames from 'classnames';
import { telephoneLink } from '@/lib/data-transformer/gindumacUtils';
import { useRouter } from 'next-translate-routes-multi-domain';
import useScrollPosition from '@/lib/hooks/useScrollPosition';
import useTranslate from '@/lib/hooks/useTranslate';
import Image from 'next/image';

const GlobalContactUsButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  // hidden if url is /product/[id] and scroll position is < 10
  const disableInPages = ['/product/[machine_id]'];
  const router = useRouter();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isMobileScreen = useWindowSize().width < 640;
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);

  const position = useScrollPosition();
  return (
    <div
      className={classNames(
        'fixed z-50',
        isOpen && isMobileScreen ? 'right-4 top-24 ' : 'bottom-4 left-4',
        disableInPages.includes(router.pathname) && position < 50 && 'hidden'
      )}
    >
      <button
        className={classNames(
          'z-50 rounded-full p-4 text-white shadow-lg',
          isOpen
            ? 'bg-brand-500 hover:bg-brand-800'
            : 'bg-green-500 hover:bg-green-600'
        )}
        onClick={toggleMenu}
      >
        {!isOpen ? (
          <FaRegComments className='h-6 w-6' />
        ) : (
          <FaPlus className='h-6 w-6 rotate-45' />
        )}
      </button>

      {isOpen && !isMobileScreen && (
        <Menu
          as='div'
          className='absolute bottom-20 left-4 w-64 rounded-lg border border-gray-300 bg-white shadow-lg'
          ref={menuRef}
        >
          <AccountManagerMenu />
        </Menu>
      )}

      {isOpen && isMobileScreen && (
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className='relative z-40'
        >
          <div className='fixed inset-0 bg-white/70' aria-hidden='true' />

          <div className='fixed inset-0 flex items-center justify-center p-4 '>
            <Dialog.Panel className='bg-white shadow-lg'>
              <Dialog.Title className='rounded'>
                <AccountManagerMenu showAllLinks />
              </Dialog.Title>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </div>
  );
};

function AccountManagerMenu({
  showAllLinks = false,
}: {
  showAllLinks?: boolean;
}) {
  const { data: accountManager, isLoading } = useAccountManagerByLocale();
  const { t } = useTranslate();
  return (
    <>
      <div className='flex flex-col items-center p-4'>
        <div className='relative mx-auto mb-4 h-20 w-20 animate-pulse rounded-full bg-gray-300'>
          <Image
            layout='fill'
            objectFit='cover'
            src={accountManager.profile_picture}
            alt={isLoading ? '' : accountManager.name}
            className='rounded-full'
          />
        </div>
        {isLoading ? (
          <div className='space-y-2'>
            <div className='h-4 w-40 animate-pulse bg-gray-300' />
            <div className='h-8 w-40 animate-pulse bg-gray-300' />
          </div>
        ) : (
          <>
            <h3 className='font-semibold'>{accountManager.name}</h3>
            <p className='text-center text-gray-600'>
              {accountManager.position}
            </p>
          </>
        )}
      </div>
      <div className='flex flex-col justify-center space-y-2 p-4'>
        <a
          className='w-full'
          href={getWhatsappLink(accountManager)}
          target='_blank'
          rel='noreferrer'
        >
          <button className='flex w-full items-center justify-center rounded-lg bg-green-500 px-4 py-2 font-primary text-white hover:bg-green-600'>
            <FaWhatsapp className='mr-2 h-8' />
            <span>{t('products:WhatsApp Chat')}</span>
          </button>
        </a>
        {showAllLinks && (
          <a
            className='w-full'
            href={telephoneLink(accountManager.phone)}
            target='_blank'
            rel='noreferrer'
          >
            <button className='flex w-full items-center justify-center rounded-lg bg-brand-400 px-4 py-2 font-primary text-white hover:bg-brand-500'>
              <FaPhoneAlt className='mr-2 h-6' />
              <span>{t('products:Call')}</span>
            </button>
          </a>
        )}
      </div>
    </>
  );
}

export default GlobalContactUsButton;
