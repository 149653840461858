globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"7473b62b510b4b515b0f6beeb29272ddd7caa60b"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://c13b61d7383b4a7f9cff93081282518e@o1214979.ingest.sentry.io/4504140269944832',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, hint) {
    // We have this unkown error (probably comming from some external extension)
    console.log('hint', hint);
    if (
      event.message &&
      event.message.includes('198230182308109283091823098102938908128390')
    ) {
      return null;
    }
    return event;
  },
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        '/_next/data/',
        '/api2/',
        '/api2/',
        'https://api.gindumac.com/api/',
        'https://apistaging.gindumac.com/api/',
        'https://crmstaging.gindumac.com/',
        'https://crm.gindumac.com',
        'https://www.gindumac.com',
      ],
    }),
  ],
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  ignoreErrors: [
    "TypeError: Cannot read properties of undefined (reading 'height')",
    /.*querySelectorAll.*/,
    /.*198230182308109283091823098102938908128390.*/,
    /.*iframe.*/,
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
